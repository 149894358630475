export enum ApiMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export enum ApiEndpoint {
  Login = 'Authentication/login',
  Store = 'Store',

  // Account
  Authenticate = 'account/authenticate',
  RefreshToken = 'account/refresh-token',

  // Brand
  CreateOrUpdateBrand = 'brand/create-or-update-brand',
  GetListBrand = 'brand/get-list-brands',
  GetBrand = 'brand/get-detail-brand',
  EditBrand = 'brand/edit-brand',
  DeleteBrand = 'brand/delete-brand',
  ToggleStatusBrand = 'brand/active-or-inactive-brand',

  // Catalog
  CreateOrUpdateCatalog = 'catalog/create-or-update-catalog',
  GetListCatalog = 'catalog/get-list-catalogs',
  GetCatalog = 'catalog/get-detail-catalog',
  EditCatalog = 'catalog/edit-catalog',
  DeleteBatchCatalogs = 'catalog/delete-batch-catalogs',
  ToggleStatusCatalog = 'catalog/active-or-inactive-catalog',

  // Post
  InsertPost = 'Post/insert-post',
  GetListPost = 'Post/get-list-posts',
  GetPost = 'Post/get-post',
  EditPost = 'Post/edit-post',
  DeletePost = 'Post/delete-post',
  ToggleStatusPost = 'Post/active-or-inactive-post',

  // Unit
  CreateOrUpdateUnit = 'Unit/create-or-update-unit',
  GetListUnit = 'Unit/get-list-units',
  GetUnit = 'Unit/get-detail-unit',
  EditUnit = 'Unit/edit-unit',
  DeleteUnit = 'Unit/delete-unit',
  ToggleStatusUnit = 'Unit/active-or-inactive-unit',

  // Invoice
  CreateOrUpdateInvoice = 'invoice/create-or-update-invoice',
  GetListInvoice = 'invoice/get-list-invoices',
  GetInvoice = 'invoice/get-detail-invoice',
  EditInvoice = 'invoice/edit-invoice',
  DeleteInvoice = 'invoice/delete-invoice',
  ToggleStatusInvoice = 'invoice/active-or-inactive-invoice',

  // Product
  CreateOrUpdateProduct = 'product/create-or-update-product',
  GetListProduct = 'product/get-list-products',
  GetProduct = 'product/get-detail-product',
  EditProduct = 'product/edit-product',
  DeleteProduct = 'product/delete-product',
  ToggleStatusProduct = 'product/active-or-inactive-product',

  // Product type
  CreateOrUpdateProductType = 'ProductType/create-or-update-product-type',
  GetListProductType = 'ProductType/get-list-product-types',
  GetProductType = 'ProductType/get-detail-product-type',
  EditProductType = 'ProductType/edit-product-type',
  DeleteProductType = 'ProductType/delete-product-type',
  ToggleStatusProductType = 'ProductType/active-or-inactive-product-type',

  // Product group
  CreateOrUpdateProductGroup = 'ProductGroup/create-or-update-product-group',
  GetListProductGroup = 'ProductGroup/get-list-product-Groups',
  GetProductGroup = 'ProductGroup/get-detail-product-group',
  EditProductGroup = 'ProductGroup/edit-product-Group',
  DeleteProductGroup = 'ProductGroup/delete-product-group',
  DeleteBatchProductGroups = 'ProductGroup/delete-batch-product-groups',
  ToggleStatusProductGroup = 'ProductGroup/active-or-inactive-product-Group',

  // Price
  CreateOrUpdatePrice = 'price/create-or-update-price',
  GetListPrice = 'price/get-list-prices',
  GetPrice = 'price/get-detail-price',
  DeletePrice = 'price/delete-price',
  DeleteBatchPrices = 'price/delete-batch-price',
  DeletePermanentPrice = 'price/permanent-delete-price',
  ToggleStatusPrice = 'price/active-or-inactive-price',

  // Country
  GetListCountries = 'country/get-list-countries',
  GetCountry = 'country/get-detail-country',
  CreateOrUpdateCountry = 'country/create-or-update-country',
  PermanentDeleteCountry = 'country/permanent-delete-country',
  DeleteCountry = 'country/delete-country',
  ToggleStatusCountry = 'country/active-or-inactive-country',

  // Bank
  GetListBanks = 'bank/get-list-banks',
  GetBank = 'bank/get-detail-bank',
  CreateOrUpdateBank = 'bank/create-or-update-bank',
  PermanentDeleteBank = 'bank/permanent-delete-bank',
  DeleteBank = 'bank/delete-bank',
  ToggleStatusBank = 'bank/active-or-inactive-bank',

  // Business type
  GetListBusinessTypes = 'businesstype/get-list-business-types',
  GetBusinessType = 'businesstype/get-detail-business-type',
  CreateOrUpdateBusinessType = 'businesstype/create-or-update-business-type',
  PermanentDeleteBusinessType = 'businesstype/permanent-delete-business-type',
  DeleteBusinessType = 'businesstype/delete-business-type',
  ToggleStatusBusinessType = 'businesstype/active-or-inactive-business-type',

  // Department
  GetListDepartments = 'department/get-list-departments',
  GetDepartment = 'department/get-detail-department',
  CreateOrUpdateDepartment = 'department/create-or-update-department',
  PermanentDeleteDepartment = 'department/permanent-delete-department',
  DeleteDepartment = 'department/delete-department',
  ToggleStatusDepartment = 'department/active-or-inactive-department',

  // Position
  GetListPositions = 'position/get-list-positions',
  GetPosition = 'position/get-detail-position',
  CreateOrUpdatePosition = 'position/create-or-update-position',
  PermanentDeletePosition = 'position/permanent-delete-position',
  DeletePosition = 'position/delete-position',
  ToggleStatusPosition = 'position/active-or-inactive-position',

  // Region
  GetListRegions = 'region/get-list-regions',
  GetRegion = 'region/get-detail-region',
  CreateOrUpdateRegion = 'region/create-or-update-region',
  PermanentDeleteRegion = 'region/permanent-delete-region',
  DeleteRegion = 'region/delete-region',
  ToggleStatusRegion = 'region/active-or-inactive-region',

  // WarehouseType
  GetListWarehouseTypes = 'warehousetype/get-list-warehouse-types',
  GetWarehouseType = 'warehousetype/get-detail-warehouse-type',
  CreateOrUpdateWarehouseType = 'warehousetype/create-or-update-warehouse-type',
  PermanentDeleteWarehouseType = 'warehousetype/permanent-delete-warehouse-type',
  DeleteWarehouseType = 'warehousetype/delete-warehouse-type',
  ToggleStatusWarehouseType = 'warehousetype/active-or-inactive-warehouse-type',

  // Warehouse
  GetListWarehouses = 'warehouse/get-list-warehouses',
  GetWarehouse = 'warehouse/get-detail-warehouse',
  CreateOrUpdateWarehouse = 'warehouse/create-or-update-warehouse',
  PermanentDeleteWarehouse = 'warehouse/permanent-delete-warehouse',
  DeleteWarehouse = 'warehouse/delete-warehouse',
  ToggleStatusWarehouse = 'warehouse/active-or-inactive-warehouse',

  // Address
  GetListAddresses = 'address/get-list-addresses',
  GetAddress = 'address/get-detail-address',
  CreateOrUpdateAddress = 'address/create-or-update-address',
  PermanentDeleteAddress = 'address/permanent-delete-address',
  DeleteAddress = 'address/delete-address',
  ToggleStatusAddress = 'address/active-or-inactive-address',

  // Business Unit
  GetListBusinessUnits = 'businessunit/get-list-business-units',
  GetBusinessUnit = 'businessunit/get-detail-business-unit',
  CreateOrUpdateBusinessUnit = 'businessunit/create-or-update-business-unit',
  PermanentDeleteBusinessUnit = 'businessunit/permanent-delete-business-unit',
  DeleteBusinessUnit = 'businessunit/delete-business-unit',
  ToggleStatusBusinessUnit = 'businessunit/active-or-inactive-business-unit',

  // Office
  GetListOffices = 'office/get-list-offices',
  GetOffice = 'office/get-detail-office',
  CreateOrUpdateOffice = 'office/create-or-update-office',
  PermanentDeleteOffice = 'office/permanent-delete-office',
  DeleteOffice = 'office/delete-office',
  ToggleStatusOffice = 'office/active-or-inactive-office',

  // PointOfSale
  GetListPointOfSales = 'pointofsale/get-list-point-of-sales',
  GetPointOfSale = 'pointofsale/get-detail-point-of-sale',
  CreateOrUpdatePointOfSale = 'pointofsale/create-or-update-point-of-sale',
  PermanentDeletePointOfSale = 'pointofsale/permanent-delete-point-of-sale',
  DeletePointOfSale = 'pointofsale/delete-point-of-sale',
  ToggleStatusPointOfSale = 'pointofsale/active-or-inactive-point-of-sale',

  // Employee
  GetListEmployees = 'employee/get-list-employees',
  GetEmployee = 'employee/get-detail-employee',
  CreateOrUpdateEmployee = 'employee/create-or-update-employee',
  PermanentDeleteEmployee = 'employee/permanent-delete-employee',
  DeleteEmployee = 'employee/delete-employee',
  ToggleStatusEmployee = 'employee/active-or-inactive-employee',

  // Inventory
  GetListInventories = 'inventory/get-list-inventories',
  GetInventory = 'inventory/get-detail-inventory',

  // Inventory component
  GetListInventoryComponents = 'inventorycomponent/get-list-inventory-components',
  GetInventoryComponentDetail = 'inventorycomponent/get-detail-inventory-component',

  // Historial import export
  GetListHistoricalImportExports = 'historicalimportexport/get-list-historical-import-exports',
  CreateOrUpdateHistoricalImportExport = 'historicalimportexport/create-or-update-historical-import-export',

  // Historial import export
  GetListHistoricalImportExportComponents = 'historicalimportexportcomponent/get-list-historical-import-export-components',
  CreateOrUpdateHistoricalImportExportComponent = 'historicalimportexportcomponent/create-or-update-historical-import-export-component',

  // Currency
  GetListCurrencies = 'currency/get-list-currencies',
  GetCurrency = 'currency/get-detail-currency',
  CreateOrUpdateCurrency = 'currency/create-or-update-currency',
  PermanentDeleteCurrency = 'currency/permanent-delete-currency',
  DeleteCurrency = 'currency/delete-currency',
  ToggleStatusCurrency = 'currency/active-or-inactive-currency',

  // Contact
  GetListContacts = 'contact/get-list-contacts',
  GetContact = 'contact/get-detail-contact',
  CreateOrUpdateContact = 'contact/create-or-update-contact',
  PermanentDeleteContact = 'contact/permanent-delete-contact',
  DeleteContact = 'contact/delete-contact',
  ToggleStatusContact = 'contact/active-or-inactive-contact',

  // Rank
  GetListRanks = 'rank/get-list-ranks',
  GetRank = 'rank/get-detail-rank',
  CreateOrUpdateRank = 'rank/create-or-update-rank',
  PermanentDeleteRank = 'rank/permanent-delete-rank',
  DeleteRank = 'rank/delete-rank',
  ToggleStatusRank = 'rank/active-or-inactive-rank',

  // Business group
  GetListBusinessGroups = 'businessgroup/get-list-business-groups',
  GetBusinessGroup = 'businessgroup/get-detail-business-group',
  CreateOrUpdateBusinessGroup = 'businessgroup/create-or-update-business-group',
  PermanentDeleteBusinessGroup = 'businessgroup/permanent-delete-business-group',
  DeleteBusinessGroup = 'businessgroup/delete-business-group',
  ToggleStatusBusinessGroup = 'businessgroup/active-or-inactive-business-group',

  // Business contact
  GetListBusinessContacts = 'businesscontact/get-list-business-contacts',
  GetBusinessContact = 'businesscontact/get-detail-business-contact',
  CreateOrUpdateBusinessContact = 'businesscontact/create-or-update-business-contact',
  PermanentDeleteBusinessContact = 'businesscontact/permanent-delete-business-contact',
  DeleteBusinessContact = 'businesscontact/delete-business-contact',
  ToggleStatusBusinessContact = 'businesscontact/active-or-inactive-business-contact',

  // Business contact
  GetListContactDeliveryAddresses = 'contactdeliveryaddress/get-list-contact-delivery-addresses',
  GetContactDeliveryAddress = 'contactdeliveryaddress/get-detail-contact-delivery-address',
  CreateOrUpdateContactDeliveryAddress = 'contactdeliveryaddress/create-or-update-contact-delivery-address',
  PermanentDeleteContactDeliveryAddress = 'contactdeliveryaddress/permanent-delete-contact-delivery-address',
  DeleteContactDeliveryAddress = 'contactdeliveryaddress/delete-contact-delivery-address',
  ToggleStatusContactDeliveryAddress = 'contactdeliveryaddress/active-or-inactive-contact-delivery-address',

  // Order
  GetListOrders = 'order/get-list-orders',
  GetOrder = 'order/get-detail-order',
  CreateOrUpdateOrder = 'order/create-or-update-order',
  PermanentDeleteOrder = 'order/permanent-delete-order',
  DeleteOrder = 'order/delete-order',
  ToggleStatusOrder = 'order/active-or-inactive-order',
  CancelOrder = 'order/cancel-order',
  CompleteOrder = 'order/complete-order',
  ActualItemRevision = 'order/actual-item-revision',

  // Actualorderitemrevisionhistory
  GetListRevisionHistory = 'actualorderitemrevisionhistory/get-list-actual-order-item-revision-histories',
  PermanentDeleteRevisionHistory = 'actualorderitemrevisionhistory/permanent-delete-actual-order-item-revision-history',
  DeleteRevisionHistory = 'actualorderitemrevisionhistory/delete-actual-order-item-revision-history',
  DeleteBatchRevisionHistory = 'actualorderitemrevisionhistory/delete-batch-actual-order-item-revision-history',
  ToggleStatusRevisionHistory = 'actualorderitemrevisionhistory/active-or-inactive-actual-order-item-revision-history',

  // Warehouse Note
  GetListWarehouseNote = 'warehousenote/get-list-warehouse-notes',
  GetWarehouseNote = 'warehousenote/get-detail-warehouse-note',
  CreateOrUpdateWarehouseNote = 'warehousenote/create-or-update-warehouse-note',
  PermanentDeleteWarehouseNote = 'warehousenote/permanent-delete-warehouse-note',
  DeleteWarehouseNote = 'warehousenote/delete-warehouse-note',
  ToggleStatusWarehouseNote = 'warehousenote/active-or-inactive-warehouse-note',
  ProcessWarehouseNote = 'warehousenote/process-warehouse-note',

  // Component
  GetListComponent = 'component/get-list-components',
  GetComponent = 'component/get-detail-component',
  CreateOrUpdateComponent = 'component/create-or-update-component',
  PermanentDeleteComponent = 'component/permanent-delete-component',
  DeleteComponent = 'component/delete-component',
  ToggleStatusComponent = 'component/active-or-inactive-component',

  // Recipe
  GetListRecipe = 'recipe/get-list-recipes',
  GetRecipe = 'recipe/get-detail-recipe',
  CreateOrUpdateRecipe = 'recipe/create-or-update-recipe',
  PermanentDeleteRecipe = 'recipe/permanent-delete-recipe',
  DeleteRecipe = 'recipe/delete-recipe',
  ToggleStatusRecipe = 'recipe/active-or-inactive-recipe',

  // Recipe item
  GetRecipeItem = 'recipeitem/get-detail-recipe-item',
  CreateOrUpdateRecipeItem = 'recipeitem/create-or-update-recipe-item',
  PermanentDeleteRecipeItem = 'recipeitem/permanent-delete-recipe-item',
  DeleteRecipeItem = 'recipeitem/delete-recipe-item',
  ToggleStatusRecipeItem = 'recipeitem/active-or-inactive-recipe-item',

  // Manufacture
  GetListManufacture = 'manufacture/get-list-manufactures',
  GetManufacture = 'manufacture/get-detail-manufacture',
  CreateOrUpdateManufacture = 'manufacture/create-or-update-manufacture',
  PermanentDeleteManufacture = 'manufacture/permanent-delete-manufacture',
  DeleteManufacture = 'manufacture/delete-manufacture',
  ToggleStatusManufacture = 'manufacture/active-or-inactive-manufacture',
  ProcessManufacture = 'manufacture/process-manufacture',
  CompleteManufacture = 'manufacture/complete-manufacture',

  // Manufacture Item
  GetManufactureItem = 'manufactureitem/get-detail-manufacture-item',
  CreateOrUpdateManufactureItem = 'manufactureitem/create-or-update-manufacture-item',
  PermanentDeleteManufactureItem = 'manufactureitem/permanent-delete-manufacture-item',
  ToggleStatusManufactureItem = 'manufactureitem/active-or-inactive-manufacture-item',
  DeleteBatchManufactureItems = 'manufactureitem/delete-batch-manufacture-items',

  // Packing
  GetListPacking = 'packinglist/get-list-packing-lists',
  GetPacking = 'packinglist/get-detail-packing-list',
  CreateOrUpdatePacking = 'packinglist/create-or-update-packing-list',
  PermanentDeletePacking = 'packinglist/permanent-delete-packing-list',
  DeletePacking = 'packinglist/delete-packing-list',
  ToggleStatusPacking = 'packinglist/active-or-inactive-packing-list',
  ProcessPacking = 'packinglist/process-packing-list',
  CompletePacking = 'packinglist/complete-packing-list',

  // User
  GetListUser = 'user/get-list-users',
  GetUser = 'user/get-detail-user',
  CreateOrUpdateUser = 'user/create-or-update-user',
  PermanentDeleteUser = 'user/permanent-delete-user',
  DeleteUser = 'user/delete-user',
  ToggleStatusUser = 'user/active-or-inactive-user',

  // Workstation Type
  GetListWorkstationType = 'workstationtype/get-list-workstation-types',
  GetWorkstationType = 'workstationtype/get-detail-workstation-type',
  CreateOrUpdateWorkstationType = 'workstationtype/create-or-update-workstation-type',
  PermanentDeleteWorkstationType = 'workstationtype/permanent-delete-workstation-type',
  DeleteWorkstationType = 'workstationtype/delete-workstation-type',
  ToggleStatusWorkstationType = 'workstationtype/active-or-inactive-workstation-type',

  // Workstation
  GetListWorkstation = 'workstation/get-list-workstations',
  GetWorkstation = 'workstation/get-detail-workstation',
  CreateOrUpdateWorkstation = 'workstation/create-or-update-workstation',
  PermanentDeleteWorkstation = 'workstation/permanent-delete-workstation',
  DeleteWorkstation = 'workstation/delete-workstation',
  ToggleStatusWorkstation = 'workstation/active-or-inactive-workstation',

  // Workstation working hour
  GetListWorkstationWorkingHour = 'workstationworkinghour/get-list-workstation-working-hours',
  GetWorkstationWorkingHour = 'workstationworkinghour/get-detail-workstation-working-hour',
  CreateOrUpdateWorkstationWorkingHour = 'workstationworkinghour/create-or-update-workstation-working-hour',
  PermanentDeleteWorkstationWorkingHour = 'workstationworkinghour/permanent-delete-workstation-working-hour',
  DeleteWorkstationWorkingHour = 'workstationworkinghour/delete-workstation-working-hour',
  ToggleStatusWorkstationWorkingHour = 'workstationworkinghour/active-or-inactive-workstation-working-hour',

  // Manufactuing operation
  GetListManufacturingOperation = 'manufacturingoperation/get-list-manufacturing-operations',
  GetManufacturingOperation = 'manufacturingoperation/get-detail-manufacturing-operation',
  CreateOrUpdateManufacturingOperation = 'manufacturingoperation/create-or-update-manufacturing-operation',
  PermanentDeleteManufacturingOperation = 'manufacturingoperation/permanent-delete-manufacturing-operation',
  DeleteManufacturingOperation = 'manufacturingoperation/delete-manufacturing-operation',
  ToggleStatusManufacturingOperation = 'manufacturingoperation/active-or-inactive-manufacturing-operation',

  // Sub operation
  GetListSubOperation = 'suboperation/get-list-suboperations',
  GetSubOperation = 'suboperation/get-detail-suboperation',
  CreateOrUpdateSubOperation = 'suboperation/create-or-update-suboperation',
  PermanentDeleteSubOperation = 'suboperation/permanent-delete-suboperation',
  DeleteSubOperation = 'suboperation/delete-suboperation',
  ToggleStatusSubOperation = 'suboperation/active-or-inactive-suboperation',

  // By product
  GetListByProduct = 'byproduct/get-list-byproducts',
  GetByProduct = 'byproduct/get-detail-byproduct',
  CreateOrUpdateByProduct = 'byproduct/create-or-update-byproduct',
  PermanentDeleteByProduct = 'byproduct/permanent-delete-byproduct',
  DeleteByProduct = 'byproduct/delete-byproduct',
  ToggleStatusByProduct = 'byproduct/active-or-inactive-byproduct',
  DeleteBatchByProducts = 'byproduct/delete-batch-byproducts',

  // Manufacture Routing
  GetListManufacturingRouting = 'manufacturingrouting/get-list-manufacturing-routings',
  GetManufacturingRouting = 'manufacturingrouting/get-detail-manufacturing-routing',
  CreateOrUpdateManufacturingRouting = 'manufacturingrouting/create-or-update-manufacturing-routing',
  PermanentDeleteManufacturingRouting = 'manufacturingrouting/permanent-delete-manufacturing-routing',
  DeleteManufacturingRouting = 'manufacturingrouting/delete-manufacturing-routing',
  ToggleStatusManufacturingRouting = 'manufacturingrouting/active-or-inactive-manufacturing-routing',
  DeleteBatchManufacturingRoutings = 'manufacturingrouting/delete-batch-manufacturing-routings',

  // Routing operation
  GetListRoutingOperation = 'routingoperation/get-list-routing-operations',
  GetRoutingOperation = 'routingoperation/get-detail-routing-operation',
  CreateOrUpdateRoutingOperation = 'routingoperation/create-or-update-routing-operation',
  PermanentDeleteRoutingOperation = 'routingoperation/permanent-delete-routing-operation',
  DeleteRoutingOperation = 'routingoperation/delete-routing-operation',
  ToggleStatusRoutingOperation = 'routingoperation/active-or-inactive-routing-operation',
  DeleteBatchRoutingOperations = 'routingoperation/delete-batch-routing-operations',

  // Commodity
  GetListCommodity = 'commodity/get-list-permissions',
  GetCommodity = 'commodity/get-detail-permission',
  CreateOrUpdateCommodity = 'commodity/create-or-update-commodity',
  PermanentDeleteCommodity = 'commodity/permanent-delete-commodity',
  DeleteCommodity = 'commodity/delete-commodity',
  ToggleStatusCommodity = 'commodity/active-or-inactive-commodity',
  DeleteBatchCommodities = 'commodity/delete-batch-commodities',

  // Commodity group
  GetListCommodityGroup = 'commoditygroup/get-list-commodity-groups',
  GetCommodityGroup = 'commoditygroup/get-detail-commodity-group',
  CreateOrUpdateCommodityGroup = 'commoditygroup/create-or-update-commodity-group',
  PermanentDeleteCommodityGroup = 'commoditygroup/permanent-delete-commodity-group',
  DeleteCommodityGroup = 'commoditygroup/delete-commodity-group',
  ToggleStatusCommodityGroup = 'commoditygroup/active-or-inactive-commodity-group',
  DeleteBatchCommodityGroups = 'commoditygroup/delete-batch-commodity-groups',

  // Delivery note
  GetListDeliveryNote = 'deliverynote/get-list-delivery-notes',
  GetDeliveryNote = 'deliverynote/get-detail-delivery-note',
  CreateOrUpdateDeliveryNote = 'deliverynote/create-or-update-delivery-note',
  PermanentDeleteDeliveryNote = 'deliverynote/permanent-delete-delivery-note',
  DeleteDeliveryNote = 'deliverynote/delete-delivery-note',
  ToggleStatusDeliveryNote = 'deliverynote/active-or-inactive-delivery-note',
  DeleteBatchDeliveryNotes = 'deliverynote/delete-batch-delivery-notes',
  ProcessDeliveryNote = 'deliverynote/process-delivery-note',

  // Delivery image
  GetListDeliveryNoteImage = 'deliverynoteimage/get-list-delivery-note-images',
  GetDeliveryNoteImage = 'deliverynoteimage/get-detail-delivery-note-image',
  CreateOrUpdateDeliveryNoteImage = 'deliverynoteimage/create-or-update-delivery-note-image',
  PermanentDeleteDeliveryNoteImage = 'deliverynoteimage/permanent-delete-delivery-note-image',
  DeleteDeliveryNoteImage = 'deliverynoteimage/delete-delivery-note-image',
  ToggleStatusDeliveryNoteImage = 'deliverynoteimage/active-or-inactive-delivery-note-image',
  DeleteBatchDeliveryNoteImages = 'deliverynoteimage/delete-batch-delivery-note-images',

  // Delivery note item
  GetListDeliveryNoteItem = 'deliverynoteitem/get-list-delivery-note-items',
  GetDeliveryNoteItem = 'deliverynoteitem/get-detail-delivery-note-item',
  CreateOrUpdateDeliveryNoteItem = 'deliverynoteitem/create-or-update-delivery-note-item',
  PermanentDeleteDeliveryNoteItem = 'deliverynoteitem/permanent-delete-delivery-note-item',
  DeleteDeliveryNoteItem = 'deliverynoteitem/delete-delivery-note-item',
  ToggleStatusDeliveryNoteItem = 'deliverynoteitem/active-or-inactive-delivery-note-item',
  DeleteBatchDeliveryNoteItems = 'deliverynoteitem/delete-batch-delivery-note-items',

  // Bank Account
  GetListBankAccount = 'bankaccount/get-list-bank-accounts',
  GetBankAccount = 'bankaccount/get-detail-bank-account',
  CreateOrUpdateBankAccount = 'bankaccount/create-or-update-bank-account',
  PermanentDeleteBankAccount = 'bankaccount/permanent-delete-bank-account',
  DeleteBankAccount = 'bankaccount/delete-bank-account',
  ToggleStatusBankAccount = 'bankaccount/active-or-inactive-bank-account',
  DeleteBatchBankAccounts = 'bankaccount/delete-batch-bank-accounts',

  // Account Balance
  GetListAccountBalance = 'accountbalance/get-list-account-balances',
  GetAccountBalance = 'accountbalance/get-detail-account-balance',
  CreateOrUpdateAccountBalance = 'accountbalance/create-or-update-account-balance',
  PermanentDeleteAccountBalance = 'accountbalance/permanent-delete-account-balance',
  DeleteAccountBalance = 'accountbalance/delete-account-balance',
  ToggleStatusAccountBalance = 'accountbalance/active-or-inactive-account-balance',
  DeleteBatchAccountBalances = 'accountbalance/delete-batch-account-balances',

  // Account Receivable
  GetListAccountReceivable = 'accountreceivable/get-list-account-receivables',
  GetAccountReceivable = 'accountreceivable/get-detail-account-receivable',

  // Payment Receipt
  GetListPaymentReceipt = 'paymentreceipt/get-list-payment-receipts',
  GetPaymentReceipt = 'paymentreceipt/get-detail-payment-receipt',
  CreateOrUpdatePaymentReceipt = 'paymentreceipt/create-or-update-payment-receipt',
  PermanentDeletePaymentReceipt = 'paymentreceipt/permanent-delete-payment-receipt',
  DeletePaymentReceipt = 'paymentreceipt/delete-payment-receipt',
  ToggleStatusPaymentReceipt = 'paymentreceipt/active-or-inactive-payment-receipt',
  DeleteBatchPaymentReceipts = 'paymentreceipt/delete-batch-payment-receipts',
  ProcessPaymentReceipt = 'paymentreceipt/process-payment-receipt',

  // Account balance transaction
  GetListAccountBalanceTransaction = 'accountbalancetransaction/get-list-account-balance-transactions',
  GetAccountBalanceTransaction = 'accountbalancetransaction/get-detail-account-balance-transaction',
  CreateOrUpdateAccountBalanceTransaction = 'accountbalancetransaction/create-or-update-account-balance-transaction',
  PermanentDeleteAccountBalanceTransaction = 'accountbalancetransaction/permanent-delete-account-balance-transaction',
  DeleteAccountBalanceTransaction = 'accountbalancetransaction/delete-account-balance-transaction',
  ToggleStatusAccountBalanceTransaction = 'accountbalancetransaction/active-or-inactive-account-balance-transaction',
  DeleteBatchAccountBalanceTransactions = 'accountbalancetransaction/delete-batch-account-balance-transactions',

  // Reason
  GetListReason = 'reason/get-list-reasons',
  GetReason = 'reason/get-detail-reason',
  CreateOrUpdateReason = 'reason/create-or-update-reason',
  PermanentDeleteReason = 'reason/permanent-delete-reason',
  DeleteReason = 'reason/delete-reason',
  ToggleStatusReason = 'reason/active-or-inactive-reason',
  DeleteBatchReasons = 'reason/delete-batch-reasons',

  // Return note
  GetListReturnNote = 'returnnote/get-list-return-notes',
  GetReturnNote = 'returnnote/get-detail-return-note',
  CreateOrUpdateReturnNote = 'returnnote/create-or-update-return-note',
  PermanentDeleteReturnNote = 'returnnote/permanent-delete-return-note',
  DeleteReturnNote = 'returnnote/delete-return-note',
  ToggleStatusReturnNote = 'returnnote/active-or-inactive-return-note',
  DeleteBatchReturnNotes = 'returnnote/delete-batch-return-notes',
  ProcessReturnNote = 'returnnote/process-return-note',

  // Production plan
  GetListProductionPlan = 'productionplan/get-list-production-plans',
  GetProductionPlan = 'productionplan/get-detail-production-plan',
  CreateOrUpdateProductionPlan = 'productionplan/create-or-update-production-plan',
  PermanentDeleteProductionPlan = 'productionplan/permanent-delete-production-plan',
  DeleteProductionPlan = 'productionplan/delete-production-plan',
  ToggleStatusProductionPlan = 'productionplan/active-or-inactive-production-plan',
  DeleteBatchProductionPlans = 'productionplan/delete-batch-production-plans',

  // Warehouse transfer
  GetListWarehouseTransfer = 'warehousetransfer/get-list-warehouse-transfers',
  GetWarehouseTransfer = 'warehousetransfer/get-detail-warehouse-transfer',
  CreateOrUpdateWarehouseTransfer = 'warehousetransfer/create-or-update-warehouse-transfer',
  PermanentDeleteWarehouseTransfer = 'warehousetransfer/permanent-delete-warehouse-transfer',
  DeleteWarehouseTransfer = 'warehousetransfer/delete-warehouse-transfer',
  ToggleStatusWarehouseTransfer = 'warehousetransfer/active-or-inactive-warehouse-transfer',
  DeleteBatchWarehouseTransfers = 'warehousetransfer/delete-batch-warehouse-transfers',
  ProcessWarehouseTransfer = 'warehousetransfer/process-warehouse-transfer',

  // Warehouse transfer item
  GetWarehouseTransferItem = 'warehousetransferitem/get-detail-warehouse-transfer-item',
  CreateOrUpdateWarehouseTransferItem = 'warehousetransferitem/create-or-update-warehouse-transfer-item',
  PermanentDeleteWarehouseTransferItem = 'warehousetransferitem/permanent-delete-warehouse-transfer-item',
  DeleteWarehouseTransferItem = 'warehousetransferitem/delete-warehouse-transfer-item',
  ToggleStatusWarehouseTransferItem = 'warehousetransferitem/active-or-inactive-warehouse-transfer-item',
  DeleteBatchWarehouseTransferItems = 'warehousetransferitem/delete-batch-warehouse-transfer-items',

  // Location
  GetListLocation = 'location/get-list-locations',
  GetLocation = 'location/get-detail-location',
  CreateOrUpdateLocation = 'location/create-or-update-location',
  PermanentDeleteLocation = 'location/permanent-delete-location',
  DeleteLocation = 'location/delete-location',
  ToggleStatusLocation = 'location/active-or-inactive-location',
  DeleteBatchLocations = 'location/delete-batch-locations',

  // Bin location
  GetListBinLocation = 'binlocation/get-list-bin-locations',
  GetBinLocation = 'binlocation/get-detail-bin-location',
  CreateOrUpdateBinLocation = 'binlocation/create-or-update-bin-location',
  PermanentDeleteBinLocation = 'binlocation/permanent-delete-bin-location',
  DeleteBinLocation = 'binlocation/delete-bin-location',
  ToggleStatusBinLocation = 'binlocation/active-or-inactive-bin-location',
  DeleteBatchBinLocations = 'binlocation/delete-batch-bin-locations',

  // Location profile
  GetListLocationProfile = 'locationprofile/get-list-location-profiles',
  GetLocationProfile = 'locationprofile/get-detail-location-profile',
  CreateOrUpdateLocationProfile = 'locationprofile/create-or-update-location-profile',
  PermanentDeleteLocationProfile = 'locationprofile/permanent-delete-location-profile',
  DeleteLocationProfile = 'locationprofile/delete-location-profile',
  ToggleStatusLocationProfile = 'locationprofile/active-or-inactive-location-profile',
  DeleteBatchLocationProfiles = 'locationprofile/delete-batch-location-profiles',

  // Location
  GetListLocationType = 'locationtype/get-list-location-types',
  GetLocationType = 'locationtype/get-detail-location-type',
  CreateOrUpdateLocationType = 'locationtype/create-or-update-location-type',
  PermanentDeleteLocationType = 'locationtype/permanent-delete-location-type',
  DeleteLocationType = 'locationtype/delete-location-type',
  ToggleStatusLocationType = 'locationtype/active-or-inactive-location-type',
  DeleteBatchLocationTypes = 'locationtype/delete-batch-location-types',

  // Zone
  GetListZone = 'zone/get-list-zones',
  GetZone = 'zone/get-detail-zone',
  CreateOrUpdateZone = 'zone/create-or-update-zone',
  PermanentDeleteZone = 'zone/permanent-delete-zone',
  DeleteZone = 'zone/delete-zone',
  ToggleStatusZone = 'zone/active-or-inactive-zone',
  DeleteBatchZones = 'zone/delete-batch-zones',

  // Zone group
  GetListZoneGroup = 'zonegroup/get-list-zonegroups',
  GetZoneGroup = 'zonegroup/get-detail-zonegroup',
  CreateOrUpdateZoneGroup = 'zonegroup/create-or-update-zonegroup',
  PermanentDeleteZoneGroup = 'zonegroup/permanent-delete-zonegroup',
  DeleteZoneGroup = 'zonegroup/delete-zonegroup',
  ToggleStatusZoneGroup = 'zonegroup/active-or-inactive-zonegroup',
  DeleteBatchZoneGroups = 'zonegroup/delete-batch-zonegroups',

  // Incoterm
  GetListIncoterm = 'incoterm/get-list-incoterms',
  GetIncoterm = 'incoterm/get-detail-incoterm',
  CreateOrUpdateIncoterm = 'incoterm/create-or-update-incoterm',
  PermanentDeleteIncoterm = 'incoterm/permanent-delete-incoterm',
  DeleteIncoterm = 'incoterm/delete-incoterm',
  ToggleStatusIncoterm = 'incoterm/active-or-inactive-incoterm',
  DeleteBatchToggleStatusIncoterms = 'incoterm/delete-batch-incoterms',

  // Tax and charge category
  GetListTaxAndChargeCategory = 'taxandchargecategory/get-list-tax-and-charge-categories',
  GetTaxAndChargeCategory = 'taxandchargecategory/get-detail-tax-and-charge-category',
  CreateOrUpdateTaxAndChargeCategory = 'taxandchargecategory/create-or-update-tax-and-charge-category',
  PermanentDeleteTaxAndChargeCategory = 'taxandchargecategory/permanent-delete-tax-and-charge-category',
  DeleteTaxAndChargeCategory = 'taxandchargecategory/delete-tax-and-charge-category',
  ToggleStatusTaxAndChargeCategory = 'taxandchargecategory/active-or-inactive-tax-and-charge-category',
  DeleteBatchTaxAndChargeCategories = 'taxandchargecategory/delete-batch-tax-and-charge-categories',

  // Tax and charge
  GetListTaxAndCharge = 'taxandcharge/get-list-tax-and-charges',
  GetTaxAndCharge = 'taxandcharge/get-detail-tax-and-charge',
  CreateOrUpdateTaxAndCharge = 'taxandcharge/create-or-update-tax-and-charge',
  PermanentDeleteTaxAndCharge = 'taxandcharge/permanent-delete-tax-and-charge',
  DeleteTaxAndCharge = 'taxandcharge/delete-tax-and-charge',
  ToggleStatusTaxAndCharge = 'taxandcharge/active-or-inactive-tax-and-charge',
  DeleteBatchTaxAndCharges = 'taxandchargecategory/delete-batch-tax-and-charges',

  // Permission
  GetListPermission = 'permission/get-list-permissions',
  GetPermission = 'permission/get-detail-permission',
  CreateOrUpdatePermission = 'permission/create-or-update-permission',
  PermanentDeletePermission = 'permission/permanent-delete-permission',
  DeletePermission = 'permission/delete-permission',
  ToggleStatusPermission = 'permission/active-or-inactive-permission',
  DeleteBatchGetListPermissions = 'permission/delete-batch-permissions',

  // Role
  GetListRole = 'role/get-list-roles',
  GetRole = 'role/get-detail-role',
  CreateOrUpdateRole = 'role/create-or-update-role',
  PermanentDeleteRole = 'role/permanent-delete-role',
  DeleteRole = 'role/delete-role',
  ToggleStatusRole = 'role/active-or-inactive-role',
  DeleteBatchGetListRoles = 'role/delete-batch-roles',

  // User role
  GetListUserRole = 'userrole/get-list-user-roles',
  GetUserRole = 'userrole/get-detail-user-role',
  CreateOrUpdateUserRole = 'userrole/create-or-update-user-role',
  PermanentDeleteUserRole = 'userrole/permanent-delete-user-role',
  DeleteUserRole = 'userrole/delete-user-role',
  ToggleStatusUserRole = 'userrole/active-or-inactive-user-role',
  DeleteBatchGetListUserRoles = 'userrole/delete-batch-user-roles',

  // Role permission
  GetListRolePermission = 'rolepermission/get-list-role-permissions',
  GetRolePermission = 'rolepermission/get-detail-role-permission',
  CreateOrUpdateRolePermission = 'rolepermission/create-or-update-role-permission',
  PermanentDeleteRolePermission = 'rolepermission/permanent-delete-role-permission',
  DeleteRolePermission = 'rolepermission/delete-role-permission',
  ToggleStatusRolePermission = 'rolepermission/active-or-inactive-role-permission',
  DeleteBatchRolePermissions = 'rolepermission/delete-batch-role-permissions',

  // Role resource
  GetListRoleResource = 'roleresource/get-list-role-resources',
  GetRoleResource = 'roleresource/get-detail-role-resource',
  CreateOrUpdateRoleResource = 'roleresource/create-or-update-role-resource',
  PermanentDeleteRoleResource = 'roleresource/permanent-delete-role-resource',
  DeleteRoleResource = 'roleresource/delete-role-resource',
  ToggleStatusRoleResource = 'roleresource/active-or-inactive-role-resource',
  DeleteBatchtRoleResources = 'roleresource/delete-batch-role-resources',

  // Resource
  GetListResource = 'resource/get-list-resources',
  GetResource = 'resource/get-detail-resource',
  CreateOrUpdateResource = 'resource/create-or-update-resource',
  PermanentDeleteResource = 'resource/permanent-delete-resource',
  DeleteResource = 'resource/delete-resource',
  ToggleStatusResource = 'resource/active-or-inactive-resource',
  DeleteBatchGetListResources = 'resource/delete-batch-resources',

  // Inspection parameter
  GetListInspectionParameter = 'inspectionparameter/get-list-inspection-parameters',
  GetInspectionParameter = 'inspectionparameter/get-detail-inspection-parameter',
  CreateOrUpdateInspectionParameter = 'inspectionparameter/create-or-update-inspection-parameter',
  PermanentDeleteInspectionParameter = 'inspectionparameter/permanent-delete-inspection-parameter',
  DeleteInspectionParameter = 'inspectionparameter/delete-inspection-parameter',
  ToggleStatusInspectionParameter = 'inspectionparameter/active-or-inactive-inspection-parameter',
  DeleteBatchInspectionParameters = 'inspectionparameter/delete-batch-inspection-parameters',

  // Inspection parameter group
  GetListInspectionParameterGroup = 'inspectionparametergroup/get-list-inspection-parameter-groups',
  GetInspectionParameterGroup = 'inspectionparametergroup/get-detail-inspection-parameter-group',
  CreateOrUpdateInspectionParameterGroup = 'inspectionparametergroup/create-or-update-inspection-parameter-group',
  PermanentDeleteInspectionParameterGroup = 'inspectionparametergroup/permanent-delete-inspection-parameter-group',
  DeleteInspectionParameterGroup = 'inspectionparametergroup/delete-inspection-parameter-group',
  ToggleStatusInspectionParameterGroup = 'inspectionparametergroup/active-or-inactive-inspection-parameter-group',
  DeleteBatchInspectionParameterGroups = 'inspectionparametergroup/delete-batch-inspection-parameter-groups',

  // Batch
  GetListBatch = 'batch/get-list-batches',
  GetBatch = 'batch/get-detail-batch',
  CreateOrUpdateBatch = 'batch/create-or-update-batch',
  PermanentDeleteBatch = 'batch/permanent-delete-batch',
  DeleteBatch = 'batch/delete-batch',
  ToggleStatusBatch = 'batch/active-or-inactive-batch',
  DeleteBatchBatches = 'batch/delete-batch-batches',

  // Serial
  GetListSerial = 'serial/get-list-serials',
  GetSerial = 'serial/get-detail-serial',
  CreateOrUpdateSerial = 'serial/create-or-update-serial',
  PermanentDeleteSerial = 'serial/permanent-delete-serial',
  DeleteSerial = 'serial/delete-serial',
  ToggleStatusSerial = 'serial/active-or-inactive-serial',
  DeleteBatchSerials = 'serial/delete-batch-serials',

  // Warranty
  GetListWarranty = 'warranty/get-list-warranties',
  GetWarranty = 'warranty/get-detail-warranty',
  CreateOrUpdateWarranty = 'warranty/create-or-update-warranty',
  PermanentDeleteWarranty = 'warranty/permanent-delete-warranty',
  DeleteWarranty = 'warranty/delete-warranty',
  ToggleStatusWarranty = 'warranty/active-or-inactive-warranty',
  DeleteBatchWarranties = 'warranty/delete-batch-warranties',

  // Lot number
  GetListLotNumber = 'lotnumber/get-list-lot-numbers',
  GetLotNumber = 'lotnumber/get-detail-lot-number',
  CreateOrUpdateLotNumber = 'lotnumber/create-or-update-lot-number',
  PermanentDeleteLotNumber = 'lotnumber/permanent-delete-lot-number',
  DeleteLotNumber = 'lotnumber/delete-lot-number',
  ToggleStatusLotNumber = 'lotnumber/active-or-inactive-lot-number',
  DeleteBatchLotNumbers = 'lotnumber/delete-batch-lot-numbers',

  // Package
  GetListPackage = 'package/get-list-packages',
  GetPackage = 'package/get-detail-package',
  CreateOrUpdatePackage = 'package/create-or-update-package',
  PermanentDeletePackage = 'package/permanent-delete-package',
  DeletePackage = 'package/delete-package',
  ToggleStatusPackage = 'package/active-or-inactive-package',
  DeleteBatchPackages = 'package/delete-batch-packages',

  // Package item
  GetListPackageItem = 'packageitem/get-list-packageitems',
  GetPackageItem = 'packageitem/get-detail-packageitem',
  CreateOrUpdatePackageItem = 'packageitem/create-or-update-packageitem',
  PermanentDeletePackageItem = 'packageitem/permanent-delete-packageitem',
  DeletePackageItem = 'packageitem/delete-packageitem',
  ToggleStatusPackageItem = 'packageitem/active-or-inactive-packageitem',
  DeleteBatchPackageItems = 'packageitem/delete-batch-packageitems',

  // Put away order
  GetListPutAwayOrder = 'putawayorder/get-list-put-away-orders',
  GetPutAwayOrder = 'putawayorder/get-detail-put-away-order',
  CreateOrUpdatePutAwayOrder = 'putawayorder/create-or-update-put-away-order',
  PermanentDeletePutAwayOrder = 'putawayorder/permanent-delete-put-away-order',
  DeletePutAwayOrder = 'putawayorder/delete-put-away-order',
  ToggleStatusPutAwayOrder = 'putawayorder/active-or-inactive-put-away-order',
  DeleteBatchPutAwayOrders = 'putawayorder/delete-batch-put-away-orders',
  ProcessPutAwayOrder = 'putawayorder/process-put-away-order',

  // Put away order item
  GetListPutAwayOrderItem = 'putawayorderitem/get-list-put-away-order-items',
  GetPutAwayOrderItem = 'putawayorderitem/get-detail-put-away-order-item',
  CreateOrUpdatePutAwayOrderItem = 'putawayorderitem/create-or-update-put-away-order-item',
  PermanentDeletePutAwayOrderItem = 'putawayorderitem/permanent-delete-put-away-order-item',
  DeletePutAwayOrderItem = 'putawayorderitem/delete-put-away-order-item',
  ToggleStatusPutAwayOrderItem = 'putawayorderitem/active-or-inactive-put-away-order-item',
  DeleteBatchPutAwayOrderItems = 'putawayorderitem/delete-batch-put-away-order-items',

  // Put away order detail
  GetListPutAwayOrderDetail = 'putawayorderdetail/get-list-put-away-order-details',
  GetPutAwayOrderDetail = 'putawayorderdetail/get-detail-put-away-order-detail',
  CreateOrUpdatePutAwayOrderDetail = 'putawayorderdetail/create-or-update-put-away-order-detail',
  PermanentDeletePutAwayOrderDetail = 'putawayorderdetail/permanent-delete-put-away-order-detail',
  DeletePutAwayOrderDetail = 'putawayorderdetail/delete-put-away-order-detail',
  ToggleStatusPutAwayOrderDetail = 'putawayorderdetail/active-or-inactive-put-away-order-detail',
  DeleteBatchPutAwayOrderDetails = 'putawayorderdetail/delete-batch-put-away-order-details',

  // Quality inspection note
  GetListQualityInspectionNote = 'qualityinspectionnote/get-list-quality-inspection-notes',
  GetQualityInspectionNote = 'qualityinspectionnote/get-detail-quality-inspection-note',
  CreateOrUpdateQualityInspectionNote = 'qualityinspectionnote/create-or-update-quality-inspection-note',
  PermanentDeleteQualityInspectionNote = 'qualityinspectionnote/permanent-delete-quality-inspection-note',
  DeleteQualityInspectionNote = 'qualityinspectionnote/delete-quality-inspection-note',
  ToggleStatusQualityInspectionNote = 'qualityinspectionnote/active-or-inactive-quality-inspection-note',
  DeleteBatchQualityInspectionNotes = 'qualityinspectionnote/delete-batch-quality-inspection-notes',
  ProcessQualityInspectionNote = 'qualityinspectionnote/process-quality-inspection-note',

  // Quality inspection item
  GetListQualityInspectionItem = 'qualityinspectionnoteitem/get-list-quality-inspection-note-items',
  GetQualityInspectionItem = 'qualityinspectionnoteitem/get-detail-quality-inspection-note-item',
  CreateOrUpdateQualityInspectionItem = 'qualityinspectionnoteitem/create-or-update-quality-inspection-note-item',
  PermanentDeleteQualityInspectionItem = 'qualityinspectionnoteitem/permanent-delete-quality-inspection-note-item',
  DeleteQualityInspectionItem = 'qualityinspectionnoteitem/delete-quality-inspection-note-item',
  ToggleStatusQualityInspectionItem = 'qualityinspectionnoteitem/active-or-inactive-quality-inspection-note-item',
  DeleteBatchQualityInspectionItems = 'qualityinspectionnoteitem/delete-batch-quality-inspection-note-items',

  // Quality inspection reading
  GetListQualityInspectionReading = 'qualityinspectionreading/get-list-quality-inspection-readings',
  GetQualityInspectionReading = 'qualityinspectionreading/get-detail-quality-inspection-reading',
  CreateOrUpdateQualityInspectionReading = 'qualityinspectionreading/create-or-update-quality-inspection-reading',
  PermanentDeleteQualityInspectionReading = 'qualityinspectionreading/permanent-delete-quality-inspection-reading',
  DeleteQualityInspectionReading = 'qualityinspectionreading/delete-quality-inspection-reading',
  ToggleStatusQualityInspectionReading = 'qualityinspectionreading/active-or-inactive-quality-inspection-reading',
  DeleteBatchQualityInspectionReadings = 'qualityinspectionreading/delete-batch-quality-inspection-readings',

  // Accountant books
  GetListAccountantBooks = 'report/get-list-accountant-books',

  // Upload Images
  UploadImages = 'file/upload-files', 
  GetListImages = 'file/get-list-images',

  // Profile
  Profile = 'account/get-profile',
}

export interface IPagingResponse<T> {
  result: {
    data: T[];
    pageIndex: number;
    pageSize: number;
    totalRecords: number;
  };
  statusCode: number;
  message: string;
  success: boolean;
  errors: any;
}

export interface IApiResponse<DataType = any> {
  result: DataType;
  statusCode: number;
  message: string;
  success: boolean;
  error: any;
}
